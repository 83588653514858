import { createBrowserHistory } from 'history';
import QueryString from 'qs';

const history = createBrowserHistory({
    basename: process.env.REACT_APP_BASE_PATH,
});

const pushPreservePath = (newPath: string) => {
    let path = newPath;
    if (newPath.includes('?')) {
        const search = QueryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        const searchNew = QueryString.parse(newPath.split('?')[1]);
        path = `${newPath.split('?')[0]}?${QueryString.stringify({
            ...search,
            ...searchNew,
        })}`;
    } else {
        path = `${newPath}${window.location.search}`;
    }
    history.push(path);
};
export { history, pushPreservePath };
