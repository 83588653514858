import { createStore, combineReducers } from 'redux';
import formReducer from './features/form.redux';

const store = createStore(
  combineReducers({ form: formReducer }),
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
/* eslint-enable */

export { store };
