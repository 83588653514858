import { getBrandName } from '../../config';

/**
 * The base resource needs to be declared in this file and not imported,
 * because it must resolve to a string directly, otheriwse it will not work in
 * the getPages and getModels methods.
 */
const BASE_RESOURCE = 'base';

export interface LogoSrc {
  logoDefault: string;
  logoSmall: string;
}

export interface Page {
  name: string;
  url: string;
  file: string;
  target: string;
}

export const chooseVwBrand = (brand: string): string => {
  switch (brand) {
    case 'vwfs':
      return 'bluelabel';
    case 'vw6':
      return 'vw6';
    case 'vwcv':
      return 'vw';
    default:
      return brand;
  }
};

export const getPages = (brand: string) =>
  import(`../../resources/${brand}/pages/index.json`).catch(() =>
    import(`../../resources/${BASE_RESOURCE}/pages/index.json`)
  );

export const getModels = (brand: string) =>
  import(`../../resources/${brand}/models`).catch(() =>
    import(`../../resources/${BASE_RESOURCE}/models`)
  );

export const getStyles = (brand: any) =>
  import(
    `@vwfs-bronson/bronson-${
      brand.includes('vw') ? chooseVwBrand(brand) : brand
    }/dist/css/style.min.css`
  ).then(() => import('../../styles.scss'));

export const getLogosSrc = async (brandName: string) => {
  let logoDefault;
  let logoSmall;

  switch (brandName) {
    case 'skoda':
    case 'vw':
      logoDefault = await import(
        `@vwfs-bronson/bronson-${brandName}/dist/img/logo.png`
      );
      logoSmall = await import(
        `@vwfs-bronson/bronson-${brandName}/dist/img/logo-small-screen.png`
      );

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      };
    case 'vw6':
    case 'seat':
    case 'audi':
      logoDefault = await import(
        `@vwfs-bronson/bronson-${brandName}/dist/img/logo.svg`
      );

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoDefault.default,
      };
    case 'vwfs':
      logoDefault = await import(
        `@vwfs-bronson/bronson-${chooseVwBrand(brandName)}/dist/img/logo.svg`
      );
      logoSmall = await import(
        `@vwfs-bronson/bronson-${chooseVwBrand(
          brandName
        )}/dist/img/logo-small-screen.svg`
      );

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      };
    case 'vwcv':
    default:
      logoDefault = await import(
        `@vwfs-bronson/bronson-${chooseVwBrand(brandName)}/dist/img/logo.png`
      );
      logoSmall = await import(
        `@vwfs-bronson/bronson-${chooseVwBrand(
          brandName
        )}/dist/img/logo-small-screen.png`
      );

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      };
  }
};

export function shouldUseVerticalBar(brandName: string = getBrandName()) {
  return ['vw', 'vwcv', 'vwfs'].includes(brandName);
}
