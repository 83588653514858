import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Spinner } from '@vwfs-bronson/bronson-react'

import initI18n from './services/i18n'
import { history } from './services/routing'
import { store } from './services/redux'
import { getStyles } from './services/brand'
import { getBrandName } from './config'

const ConnectedApp = React.lazy(() => import('./App'))

export const withRouter = (Component: any) => {
  const Wrapper = (props: any) => <Component history={history} {...props} />
  return Wrapper
}

const Init: React.FC = () => {
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    Promise.all([getStyles(getBrandName()), initI18n(getBrandName())])
      .then(() => {
        setLoaded(true)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])
  return loaded ? (
    <React.Suspense fallback={<Spinner fullPage />}>
      <Provider store={store}>
        <Router history={history} location={history.location}>
          <ConnectedApp />
        </Router>
      </Provider>
    </React.Suspense>
  ) : null
}

function render(): void {
  const rootElement = document.getElementById('root')
  ReactDOM.render(<Init />, rootElement)
}

declare let module: { hot: any }

if (module.hot) {
  module.hot.accept(render)
}

render()
