export const RESET_FORM_DATA = 'form//RESET_FORM_DATA';
export const SAVE_FORM_DATA = 'form//SAVE_FORM_DATA';
export const SET_STEP_DATA = 'form//SET_STEP_DATA';

export interface Store {
  form: {
    data?: {
      mobilityTerms: {
        brand?: any;
        budget?: any;
        vehicleType?: string;
        transmission?: string;
        fuelType?: string;
        hourTime?: string;
      };
      personalDetails: {
        email?: any;
        phone?: any;
        firstName?: string;
        lastName?: string;
      };
    };
    stepData: [];
  };
}

export interface State {
  data?: any;
}

export interface Data {
  mobilityTerms?: {
    brand?: any;
    budget?: any;
    vehicleType?: string;
    transmission?: string;
    fuelType?: string;
    hourTime?: string;
  };
  personalDetails?: {
    email?: any;
    phone?: any;
    firstName?: string;
    lastName?: string;
  };
}

interface SaveFormData {
  type: typeof SAVE_FORM_DATA;
  payload: Data;
}

interface ResetFormData {
  type: typeof RESET_FORM_DATA;
}

interface SetStepData {
  type: typeof SET_STEP_DATA;
  payload: {
    step: number;
    data: Data;
  };
}

export type DataActionTypes = SaveFormData | ResetFormData | SetStepData;

export function saveFormData(data: Data): DataActionTypes {
  return {
    type: SAVE_FORM_DATA,
    payload: data,
  };
}

export function resetFormData(): DataActionTypes {
  return {
    type: RESET_FORM_DATA,
  };
}

export function getFormData(store: Store): Data | undefined {
  return store.form.data;
}

export function setStepData(step: number, data: Data): DataActionTypes {
  return {
    type: SET_STEP_DATA,
    payload: {
      step,
      data,
    },
  };
}

export function getStepData(store: Store, step: number): [] {
  return store.form.stepData && store.form.stepData[step];
}

const initialState = {
  data: {} as Data,
};

// eslint-disable-next-line func-names
export default function (state = initialState, action: DataActionTypes): State {
  switch (action.type) {
    case RESET_FORM_DATA:
      return {
        ...state,
        data: {},
      };

    case SAVE_FORM_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
