import { AnalyticsObj } from './services/analytics';

export const getBrandName = (): string =>
  process.env.REACT_APP_WEBSITE_BRAND === 'vw5' || !process.env.REACT_APP_WEBSITE_BRAND
    ? 'vw'
    : process.env.REACT_APP_WEBSITE_BRAND

export const ANALYTICS_DEFAULTS: Partial<AnalyticsObj> = {
  core: {
    stagingEnvironment: process.env.REACT_APP_STAGE as string,
    dataLayerVersion: '2.12',
    pageInfo: {
      pageName: null,
      intendedCustomerDeviceType: 'all',
      version: 'Rel 1.0',
      releaseDate: '2023-05-30',
      language: 'de',
      market: 'DE',
      publisher: 'DU',
    },
    category: {
      primaryCategory: 'Pre sales',
      secondaryCategory: 'Pre sales',
      siteType: 'Pre sales',
      inventoryType: 'not available',
      maturityLevel: 'Lead',
      contractType: 'New',
    },
    attributes: {
      journeyType: 'customer-facing-journey',
      viewChange: null,
      brand: 'VWFS',
    },
  },
  error: {
    errorCode: null,
    errorMessage: null,
    errorCausingURL: null,
  },
  event: null,
  eventInfo: [
    {
      eventType: null,
      eventAction: null,
      eventTargetURL: null,
      linkInformation: null,
    },
  ],
  design: {
    browserResolutionBreakpoint: null,
  },
  customerData: {
    loggedInUserGroup: false,
  },
  filter: [
    {
      filterName: null,
      filterValue: null,
      filterAction: null,
    },
  ],
}

export const hourTimeBasedConfig: { [index: string]: any } = {
  days: {
    brands: ['vw', 'audi'],
    fuelType: false,
    showBrand: true,
  },
  weeks: {
    brands: ['vw', 'audi'],
    fuelType: false,
    showBrand: true,
  },
  months: {
    brands: [],
    fuelType: false,
    showBrand: false,
  },
  years: {
    brands: ['vw', 'audi', 'skoda', 'seat'],
    fuelType: true,
    showBrand: true,
  },
}
